import Apiservice from '@/core/services/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;


export default class InvoiceService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}user/invoice`;
    }

    getInvoice(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url);
    }

}