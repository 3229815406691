<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <!--begin: Wizard Form-->
            <div class="row">
              <div class="col-md-6 col-lg-6 col-sm-12">
                <h4>
                  <span>Invoice </span>
                </h4>
                <router-link :to="{name:'dashboard'}">
                  Dashboard
                </router-link>
                \
                Invoice

              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <v-container class="my-4">
                  <v-card outlined>
                    <v-card-text>
                      <v-row class="text-center mb-4">
                        <v-col>
                          <v-img src="/media/logos/yeldemy_main_logo.png" alt="Kathmandu World School Logo" class="mb-2" contain width="100"></v-img>
                          <h1 class="text-h4 font-weight-bold">MONTHLY BILL</h1>
                        </v-col>
                      </v-row>
                      <v-row class="mb-2">
                        <v-col cols="6">
                          <p><strong>Bill No.</strong> 06355</p>
                          <p><strong>PAN:</strong> 301720803</p>
                        </v-col>
                        <v-col cols="6" class="text-right">
                          <p><strong>Date:</strong> 2081-01-18</p>
                          <p><strong>Month:</strong> Baishakh</p>
                        </v-col>
                      </v-row>
                      <v-row class="mb-2">
                        <v-col>
                          <p><strong>STUDENT'S NAME:</strong> Kinjal Parajuli</p>
                          <p><strong>STUDENT ID:</strong> KSKP80479</p>
                          <p><strong>ROLL NO.:</strong> </p>
                          <p><strong>GRADE:</strong> Nine/Grade 9 (2081)</p>
                        </v-col>
                      </v-row>
                      <v-row class="text-center font-weight-bold mb-2">
                        <v-col>
                          <h2 class="text-h5">BAISHAK INVOICE 2081</h2>
                        </v-col>
                      </v-row>
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                          <tr>
                            <th class="text-left">S.N.</th>
                            <th class="text-left">(Fee Heading)</th>
                            <th class="text-left">Time(s)</th>
                            <th class="text-left">Amount (Rs.)</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr>
                            <td>1.</td>
                            <td>Tuition Fee</td>
                            <td class="text-center">1</td>
                            <td class="text-right">35,000.00</td>
                          </tr>
                          <tr>
                            <td>2.</td>
                            <td>Educational Materials</td>
                            <td class="text-center">1</td>
                            <td class="text-right">2,700.00</td>
                          </tr>
                          <tr>
                            <td>3.</td>
                            <td>Special ECA</td>
                            <td class="text-center">1</td>
                            <td class="text-right">3,500.00</td>
                          </tr>
                          <tr>
                            <td>4.</td>
                            <td>Meal Charges</td>
                            <td class="text-center">1</td>
                            <td class="text-right">8,500.00</td>
                          </tr>
                          <tr>
                            <td>5.</td>
                            <td>Registration Fee</td>
                            <td class="text-center">1</td>
                            <td class="text-right">0.00</td>
                          </tr>
                          <tr>
                            <td>6.</td>
                            <td>Security Deposit</td>
                            <td class="text-center">1</td>
                            <td class="text-right">0.00</td>
                          </tr>
                          <tr>
                            <td>7.</td>
                            <td>Hostel Fee (Baishakh)</td>
                            <td class="text-center">1</td>
                            <td class="text-right">28,000.00</td>
                          </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                      <v-row class="mb-2">
                        <v-col class="text-right">
                          <p><strong>This Month's Total:</strong> 77,700.00</p>
                          <p><strong>Previous Balance (Dr.):</strong> 49,998.95</p>
                          <p><strong>Total (Dr.):</strong> 1,27,698.95</p>
                        </v-col>
                      </v-row>
                      <v-row class="text-center text-caption mt-4">
                        <v-col>
                          <p>Surya Binayak - 7 | Gundu, Bhaktapur, Nepal | Tel: 5090337</p>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-container>
              </div>
              <div class="col-12 text-right">
                <v-btn class="btn btn-primary text-white" @click="generateReport">Generate Invoice PDF</v-btn>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <vue-html2pdf
                    :show-layout="false"
                    :float-layout="true"
                    :enable-download="false"
                    :preview-modal="true"
                    :paginate-elements-by-height="1400"
                    filename="invoice"
                    :pdf-quality="2"
                    :manual-pagination="false"
                    pdf-format="a4"
                    pdf-orientation="portrait"
                    pdf-content-width="100%"
                    @progress="onProgress($event)"
                    @hasStartedGeneration="hasStartedGeneration()"
                    @hasGenerated="hasGenerated($event)"
                    ref="html2Pdf"
                >
                  <section slot="pdf-content">
                      <v-card outlined>
                        <v-card-text>
                          <v-row class="text-center mb-4">
                            <v-col>
                              <v-img src="/media/logos/yeldemy_main_logo.png" alt="Kathmandu World School Logo" class="mb-2" contain width="100"></v-img>
                              <h1 class="text-h4 font-weight-bold">MONTHLY BILL</h1>
                            </v-col>
                          </v-row>
                          <v-row class="mb-2">
                            <v-col cols="6">
                              <p><strong>Bill No.</strong> 06355</p>
                              <p><strong>PAN:</strong> 301720803</p>
                            </v-col>
                            <v-col cols="6" class="text-right">
                              <p><strong>Date:</strong> 2081-01-18</p>
                              <p><strong>Month:</strong> Baishakh</p>
                            </v-col>

                            <v-col cols="6">
                              <p><strong>STUDENT'S NAME:</strong> Kinjal Parajuli</p>
                              <p><strong>STUDENT ID:</strong> KSKP80479</p>
                            </v-col>
                            <v-col cols="6" class="text-right">
                              <p><strong>ROLL NO.:</strong> </p>
                              <p><strong>GRADE:</strong> Nine/Grade 9 (2081)</p>
                            </v-col>
                          </v-row>

                          <v-row class="text-center font-weight-bold mb-2">
                            <v-col>
                              <h2 class="text-h5">BAISHAK INVOICE 2081</h2>
                            </v-col>
                          </v-row>
                          <v-simple-table>
                            <template v-slot:default>
                              <thead>
                              <tr>
                                <th class="text-left">S.N.</th>
                                <th class="text-left">(Fee Heading)</th>
                                <th class="text-left">Time(s)</th>
                                <th class="text-left">Amount (Rs.)</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr>
                                <td>1.</td>
                                <td>Tuition Fee</td>
                                <td class="text-center">1</td>
                                <td class="text-right">35,000.00</td>
                              </tr>
                              <tr>
                                <td>2.</td>
                                <td>Educational Materials</td>
                                <td class="text-center">1</td>
                                <td class="text-right">2,700.00</td>
                              </tr>
                              <tr>
                                <td>3.</td>
                                <td>Special ECA</td>
                                <td class="text-center">1</td>
                                <td class="text-right">3,500.00</td>
                              </tr>
                              <tr>
                                <td>4.</td>
                                <td>Meal Charges</td>
                                <td class="text-center">1</td>
                                <td class="text-right">8,500.00</td>
                              </tr>
                              <tr>
                                <td>5.</td>
                                <td>Registration Fee</td>
                                <td class="text-center">1</td>
                                <td class="text-right">0.00</td>
                              </tr>
                              <tr>
                                <td>6.</td>
                                <td>Security Deposit</td>
                                <td class="text-center">1</td>
                                <td class="text-right">0.00</td>
                              </tr>
                              <tr>
                                <td>7.</td>
                                <td>Hostel Fee (Baishakh)</td>
                                <td class="text-center">1</td>
                                <td class="text-right">28,000.00</td>
                              </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                          <v-row class="mb-2">
                            <v-col class="text-right">
                              <p><strong>This Month's Total:</strong> 77,700.00</p>
                              <p><strong>Previous Balance (Dr.):</strong> 49,998.95</p>
                              <p><strong>Total (Dr.):</strong> 1,27,698.95</p>
                            </v-col>
                          </v-row>
                          <v-row class="text-center text-caption mt-4">
                            <v-col>
                              <p>Surya Binayak - 7 | Gundu, Bhaktapur, Nepal | Tel: 5090337</p>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                  </section>
                </vue-html2pdf>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
import InvoiceService from "@/core/services/invoice/InvoiceService";
import SiteSettingService from "@/core/services/site-setting/SiteSettingService";

const siteSettingService = new SiteSettingService();
const invoiceService = new InvoiceService();
export default {
  name: "Index",
  components: {
    VueHtml2pdf
  },
  data() {
    return {
      invoice: {},
      setting: {}
    }
  },
  computed: {
    invoice_id() {
      return this.$route.params.id;
    }
  },
  mounted() {
    this.getSetting()
    this.getInvoice()
  },
  methods: {
    getSetting() {
      siteSettingService.show().then(response => {
        this.setting = response.data
      })
    },
    getInvoice() {
      invoiceService.getInvoice(this.invoice_id).then(response => {
        this.invoice = response.data
      })
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf()
    },
    onProgress(event) {
    },
    hasStartedGeneration(event) {
    },
    hasGenerated(event) {
    },
  }
}
</script>


